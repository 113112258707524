import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Link } from "../../core/interfaces/primitives";

function _getSocialIcons(): Link[] {
  const { mdx } = useStaticQuery(graphql`
    query GetSocialIcons {
      mdx(fields: { page: { eq: "biography" } }) {
        frontmatter {
          author {
            links {
              icon
              name
              url
            }
          }
        }
      }
    }
  `);
  return mdx.frontmatter.author.links;
}

export default function SocialIcons(props: { className: string }): React.ReactElement {
  const data = _getSocialIcons();

  return (
    <div className={props.className}>
      {data.map((link, link_ix) => (
        <a href={link.url} target="_blank" key={link_ix}>
          <button
            type="button"
            className="bg-white text-gray-900 shadow-lg text-lg h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 hover:text-gray-600 transition-all"
          >
            <i className={link.icon}></i>
          </button>
        </a>
      ))}
    </div>
  );
}
