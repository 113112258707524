import { Link } from "gatsby";
import React, { useState } from "react";

export default function Navbar(): React.ReactElement {
  const [navbarOpen, setNavbar] = useState(false);

  const toggleNavbar = () => setNavbar(!navbarOpen);

  return (
    <nav className="w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white dark:bg-gray-900 shadow">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            to="/"
            className="text-gray-800 dark:text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
          >
            Davi Frossard
          </Link>
          <button
            className="cursor-pointer text-xl text-gray-100 dark:text-white leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => toggleNavbar()}
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div className={`lg:flex flex-grow items-center ${navbarOpen ? "block" : "hidden"}`}>
          <ul className="flex flex-col lg:flex-row list-none mr-auto align-right mt-3">
            {/* <li className="flex items-center">
              <Link
                to="/blog"
                className="hover:text-gray-600 text-gray-800 dark:text-white ml-auto py-2 flex items-center text-xs uppercase font-bold"
              >
                <i className="text-gray-500 far fa-file-alt text-lg leading-lg mr-2"></i>
                Blog
              </Link>
            </li> */}
          </ul>
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto"></ul>
        </div>
      </div>
    </nav>
  );
}
