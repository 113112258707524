import React from "react";
import Helmet from "react-helmet";
import Footer from "./footer";
import Navbar from "./navbar";

export default function Layout(props: {
  children: React.ReactElement[] | React.ReactElement;
}): React.ReactElement {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Davi Frossard</title>
        <link rel="shortcut icon" href="/assets/icons/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/assets/icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/icons/favicon-16x16.png" />
        <link rel="manifest" href="/assets/icons/site.webmanifest" />
        <script
          defer
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon='{"token": "1608806c078a45bfa8eaabc010b665c1"}'
        ></script>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <header className="sticky top-0 z-50 w-full ">
        <Navbar />
      </header>
      <main>{props.children}</main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}
